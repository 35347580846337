<template>
  <div>
    <van-nav-bar left-arrow>
      <template #left>
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          @click="$router.go(-1)"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">设置新密码</span>
      </template>
    </van-nav-bar>
    <van-form>
      <van-field
        label-class="field_view"
        v-model="newPw"
        name="用户名"
        label="新密码"
        type="password"
        placeholder="请输入新密码"
        label-width="100px"
      />
      <van-field
        center
        clearable
        v-model="rePw"
        type="password"
        label="确认密码"
        placeholder="请确认新密码"
        label-width="100px"
      />
      <van-button
        round
        block
        type="info"
        @click="submit"
        color="linear-gradient(to right, #01B15B, #49D0A2)"
        class="nex_password_view"
        >提交</van-button
      >
    </van-form>
  </div>
</template>

<script>
import { update_pw } from "@/service/api/index.js";
import { removeToken } from "@/utils/auth.js";
import { Form } from "vant";
export default {
  data() {
    return {
      newPw: "",
      rePw: "",
    };
  },
  methods: {
    submit() {
      const formData = new FormData();
      formData.append("newPw", this.newPw);
      formData.append("rePw", this.rePw);
      update_pw(formData).then((res) => {
        if (res.status === 200) {
          removeToken("token");
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>

<style scoped>
.van-button--block {
  width: 90%;
  height: 35px;
  margin: 30px auto;
}
</style>